<template>
  <b-nav-item-dropdown class="dropdown-notification mr-25" menu-class="dropdown-menu-media" right>
    <template #button-content>
      <feather-icon :badge="totalNotification" badge-classes="bg-danger" class="text-body" icon="BellIcon" size="21" />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notificações</h4>
        <b-badge pill variant="light-primary">
          {{ totalNotification }}
          {{
            totalNotification > 1
              ? 'novas notificações'
              : totalNotification == 1
              ? 'nova notificação'
              : totalNotification == 0
              ? 'notificações'
              : ''
          }}
        </b-badge>
      </div>
    </li>

    <template v-if="isBusy">
      <div class="loading-indicator align-middle">
        <b-spinner small label="Small Spinner" class="align-middle mr-50"></b-spinner>
        <span>Carregando...</span>
      </div>
    </template>
    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-else
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link v-for="item in notificacoes" :key="item.title">
        <b-media>
          <h6 class="font-weight-bolder media-heading">
            {{ item.title }}
          </h6>
          <small class="font-weight-bolde media-heading">{{ item.text }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block @click="deleteNotification">
        Limpar as notificações
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
  import { BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox } from 'bootstrap-vue'
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      BNavItemDropdown,
      BBadge,
      BMedia,
      BLink,
      BAvatar,
      VuePerfectScrollbar,
      BButton,
      BFormCheckbox,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        isBusy: false,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        notificacoes: [],
        totalNotification: '',
        intervalId: null,
      }
    },
    mounted() {
      this.carregarGrid()

      this.intervalId = setInterval(() => {
        this.carregarGrid()
      }, 30000)
    },
    beforeDestroy() {
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }
    },
    methods: {
      carregarGrid() {
        if (this.userData.id) {
          useJwt
            .get(`utils/buscarNotificacoes/${this.userData.id}`)
            .then((response) => {
              this.notificacoes = response.data
              this.totalNotification = response.data.length
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      deleteNotification() {
        this.isBusy = true
        useJwt
          .delete(`utils/deleteNotificacoes`, this.userData.id)
          .then((response) => {
            this.notificacoes = []
            this.totalNotification = 0
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.carregarGrid()
            this.isBusy = false
          })
      },
    },
    setup() {
      const perfectScrollbarSettings = {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      }

      return {
        perfectScrollbarSettings,
      }
    },
  }
</script>

<style>
  .loading-indicator {
    align-items: center;
    text-align: center;
    padding: 30px;
  }
</style>
